<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <material-card color="#4e4e4e" title="Fornecedores">
          <v-toolbar color="#FFF">
            <v-text-field
              hide-details
              prepend-icon
              single-line
              v-model="pesquisa"
            ></v-text-field>
            <v-btn color="#4e4e4e" text @click="findFornecedor()">Buscar</v-btn>
            <v-btn color="#4e4e4e" text @click="dialogCriar = true"
              >Cadastrar</v-btn
            >
          </v-toolbar>
          <v-data-table :headers="headers" :items="items">
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.nome }} {{ item.sobrenome }}</td>
              <td>{{ item.telefone }}</td>
              <td v-if="item.endereco">{{ item.endereco.estado }}</td>
              <td v-else>-</td>
              <td v-if="item.instagram">{{ item.instagram }}</td>
              <td v-else>-</td>
              <td style="width: 50px; height: 50px; object-fit: contain">
                <v-toolbar color="rgba(0,0,0,0)" flat>
                  <v-btn
                    color="rgba(0,0,0,0)"
                    flat
                    @click="showDetalhes(item._id)"
                    ><v-icon color="tertiary">mdi-magnify</v-icon></v-btn
                  >
                  <v-btn
                    color="rgba(0,0,0,0)"
                    flat
                    @click="deleteItem(item._id)"
                    ><v-icon color="tertiary">mdi-close</v-icon></v-btn
                  >
                </v-toolbar>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

      <v-snackbar
        color="#000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbar"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>Campanha atualizado com sucesso!</div>
        <v-icon size="16" @click="snackbar = false">mdi-close-circle</v-icon>
      </v-snackbar>

      <v-snackbar
        color="#BA0000"
        :bottom="bottom"
        :top="top"
        :left="left"
        :right="right"
        v-model="snackbarerror"
        dark
      >
        <v-icon color="#fff" class="mr-3">mdi-bell-plus</v-icon>
        <div>
          Verifique os dados e tente novamente
          <br />Verifique a conexão com a internet!
        </div>
        <v-icon size="16" @click="snackbarerror = false"
          >mdi-close-circle</v-icon
        >
      </v-snackbar>
    </v-layout>

    <v-dialog v-model="comprasrealizadasdialog" width="900">
      <v-card v-if="fornecedorSelecionado !== null">
        <v-card-text>Fornecedor</v-card-text>
        <v-card-text> Nome: {{ fornecedorSelecionado.nome }} </v-card-text>
        <v-card-text v-if="fornecedorSelecionado.telefone != null">
          Telefone: {{ fornecedorSelecionado.telefone }}
        </v-card-text>
        <v-card-text v-else> Telefone: Não informado </v-card-text>

        <v-card-text v-if="fornecedorSelecionado.instagram != null">
          Instagram: {{ fornecedorSelecionado.instagram }}
        </v-card-text>
        <v-card-text v-else> Instagram: Não informado </v-card-text>

        <v-card-text v-if="fornecedorSelecionado.complemento != null">
          Complemento: {{ fornecedorSelecionado.complemento }}
        </v-card-text>
        <v-card-text v-else> Complemento: Não informado </v-card-text>

        <v-card-text v-if="fornecedorSelecionado.email != null">
          E-mail: {{ fornecedorSelecionado.instagram }}
        </v-card-text>
        <v-card-text v-else> E-mail: Não informado </v-card-text>

        <v-card-text v-if="fornecedorSelecionado.endereco != null">
          Endereço: {{ fornecedorSelecionado.endereco.rua }},
          {{ fornecedorSelecionado.endereco.numero }} -
          {{ fornecedorSelecionado.endereco.bairro }} -
          {{ fornecedorSelecionado.endereco.cidade }}/{{
            fornecedorSelecionado.endereco.estado
          }}
        </v-card-text>
        <v-card-text v-else> Endereço: Não informado </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = true; comprasrealizadasdialog = false">Editar</v-btn>
        </v-card-actions>
      </v-card>
      <v-text v-else>
        <v-card-text>Nenhuma compra realizada</v-card-text>
      </v-text>
    </v-dialog>

    <v-dialog v-if="dialog == true" v-model="dialog" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Editar Fornecedor</v-card-title
        >
        <v-card-text v-if="fornecedorSelecionado">
          Nome:
          <v-text-field v-model="fornecedorSelecionado.nome"></v-text-field>
        </v-card-text>

        <v-card-text v-if="fornecedorSelecionado">
          Telefone:
          <v-text-field v-model="fornecedorSelecionado.telefone"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Instagram:
          <v-text-field v-model="fornecedorSelecionado.instagram"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Complemento:
          <v-text-field v-model="fornecedorSelecionado.instagram"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Email:
          <v-text-field v-model="fornecedorSelecionado.instagram"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-text v-if="fornecedorSelecionado">
          Rua:
          <v-text-field v-model="fornecedorSelecionado.rua"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Numero:
          <v-text-field v-model="fornecedorSelecionado.numero"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Bairro:
          <v-text-field v-model="fornecedorSelecionado.bairro"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Cidade:
          <v-text-field v-model="fornecedorSelecionado.cidade"></v-text-field>
        </v-card-text>
        <v-card-text v-if="fornecedorSelecionado">
          Estado:
          <v-text-field v-model="fornecedorSelecionado.estado"></v-text-field>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updateItem()"
            >Atualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCriar" width="900">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Criar Fornecedor</v-card-title
        >
        <v-card-text style="font-weight: bold; text-transform: uppercase">
          Dados do Fornecedor
        </v-card-text>
        <v-card-text>
          Nome:
          <v-text-field v-model="criar.nome"></v-text-field>
        </v-card-text>
        <v-card-text>
          Telefone:
          <v-text-field v-model="criar.telefone"></v-text-field>
        </v-card-text>
        <v-card-text>
          E-mail:
          <v-text-field v-model="criar.email"></v-text-field>
        </v-card-text>
        <v-card-text>
          Instagram:
          <v-text-field v-model="criar.instagram"></v-text-field>
        </v-card-text>
        <v-card-text>
          Complemento/Observações:
          <v-text-field v-model="criar.complemento"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-text style="font-weight: bold; text-transform: uppercase">
          Endereço
        </v-card-text>
        <v-card-text>
          Rua:
          <v-text-field v-model="criar.rua"></v-text-field>
        </v-card-text>
        <v-card-text>
          Numero:
          <v-text-field v-model="criar.numero"></v-text-field>
        </v-card-text>
        <v-card-text>
          Cidade:
          <v-text-field v-model="criar.cidade"></v-text-field>
        </v-card-text>
        <v-card-text>
          Estado:
          <v-text-field v-model="criar.estado"></v-text-field>
        </v-card-text>
        <v-card-text>
          Complemento:
          <v-text-field v-model="criar.complementoEndereco"></v-text-field>
        </v-card-text>
        <v-card-text>
          Referência:
          <v-text-field v-model="criar.referencia"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="criarFornecedor()">Criar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogeditar: false,
    dialogCriar: false,
    itemSelecionado: null,
    criar: {
      _id: "",
      nome: "",
      telefone: "",
      email: "",
      instagram: "",
      complemento: "",
      rua: "",
      numero: "",
      cidade: "",
      estado: "",
      complementoEndereco: "",
      referencia: "",
    },
    comprasrealizadasdialog: false,
    comprasrealizadas: [],
    fornecedorSelecionado: null,
    pesquisa: "",
    codigoDeBarras: "",
    headers_prod: [
      {
        text: "Nome",
        sortable: false,
        value: "nome",
      },
      { text: "Código de Barras", sortable: false, value: "codigoDeBarras" },
      // { text: "Actions", value: "actions", sortable: false }
    ],
    file: "",
    headers: [
      {
        sortable: false,
        text: "Nome",
        value: "nome",
      },
      {
        sortable: false,
        text: "Telefone",
        value: "telefone",
      },
      {
        sortable: false,
        text: "Estado",
        value: "estado",
      },
      {
        sortable: false,
        text: "Instagram",
        value: "instagram",
      },
      {
        sortable: false,
        text: "Ações",
      },
    ],
    tipos: [
      {
        nome: "Administrador",
        valor: "administrador",
      },
      {
        nome: "Vendedor",
        valor: "vendedor",
      },
      {
        nome: "Cliente",
        valor: "cliente",
      },
    ],
    tipoSelecionado: "",
    snackbar: false,
    snackbarerror: false,
    select: null,
    selecionado: null,
    items: [],
    bottom: false,
    top: false,
    left: false,
    right: false,
  }),
  beforeMount() {
    this.getFornecedores();
  },
  methods: {
    getFornecedores() {
      this.$http
        .get("/fornecedores")
        .then((res) => {
          this.items = res.data.docs;
        })
        .catch((err) => {
          if (err.error) alert(err.error);
        });
    },
    findFornecedor() {
      if (this.pesquisa != "") {
        this.$http
          .get(`/fornecedores/buscar?query=${this.pesquisa}`)
          .then((res) => {
            this.items = res.data.docs;
          })
          .catch((err) => {
            if (err.error) alert(err.error);
          });
      } else {
        this.getClientes();
      }
    },
    criarFornecedor() {
      this.$http
        .post(`/fornecedores/create`, this.criar)
        .then((res) => {
          alert("Fornecedor criado!");
          this.dialogCriar = false;
          this.getFornecedores();
        })
        .catch((err) => alert(err.error));
    },
    showDetalhes(item) {
      // console.log(item);
      this.$http
        .get(`/fornecedores/${item}`)
        .then((res) => {
          this.fornecedorSelecionado = res.data;
          this.comprasrealizadasdialog = true;
        })
        .catch((err) => {
          if (err.error) alert(err.error);
        });
    },
    updateItem(){
      if(confirm("Deseja realmente atualizar o fornecedor?")){
        this.$http.put(`/fornecedores/${this.fornecedorSelecionado._id}`, this.fornecedorSelecionado).then(() => {
          alert("Fornecedor atualizado com sucesso!");
          this.getFornecedores();
        })
      } else{
        
      }
    },
    deleteItem(item){
      if(confirm("Deseja realmente deletar o fornecedor?")){
        this.$http.delete(`/fornecedores/${item}`).then(() => {
          alert("Fornecedor deletado com sucesso!");
          this.getFornecedores();
        })
      } else{
        
      }
    },
    fecharDialog() {
      this.dialog = false;
    },
  },
};
</script>
